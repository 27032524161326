import React from "react";
import "../App.css";
import { Link } from "react-router-dom";
import "@vaadin/vaadin-icons";
import "@fortawesome/fontawesome-free";
import "@fortawesome/fontawesome-free/js/brands";

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Simon Schmidt</h1>
      </header>
      <body className="App-body">
        <div class="row">
          <div class="column">
            <Link to="/photos" style={{ textDecoration: "none" }}>
              <text className="App-link">
                <iron-icon icon="vaadin:camera"></iron-icon> photos
              </text>
            </Link>
            <br />
            <a
              className="App-link"
              href="https://www.instagram.com/simon_schmidt"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fab fa-instagram fa-xs"></i> instagram
            </a>
            <br />
            <a
              className="App-link"
              href="https://www.twitter.com/simon_schmidt"
              target="_blank"
              rel="noopener noreferrer"
            >
              <iron-icon icon="vaadin:twitter"></iron-icon> twitter
            </a>
            <br />
            <Link to="/cv" style={{ textDecoration: "none" }}>
              <text className="App-link">
                <iron-icon icon="vaadin:academy-cap"></iron-icon> cv
              </text>
            </Link>
            <br />
            <Link to="/about" style={{ textDecoration: "none" }}>
              <text className="App-link">
                <iron-icon icon="vaadin:info-circle-o"></iron-icon> about
              </text>
            </Link>
          </div>
        </div>
      </body>
      <footer className="footer">
        <br />© Simon Schmidt 2020
      </footer>
    </div>
  );
}

export default Home;

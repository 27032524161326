import React from "react";
import { Link } from "react-router-dom";
import "../App.css";
import "firebase";

var firebase = require("firebase/app");
require("firebase/storage");

var firebaseConfig = {
  apiKey: "AIzaSyDFJHpBhKIqE3w9jT88-_NG7SpKv_UtxZo",
  authDomain: "reactwebsimon.firebaseapp.com",
  databaseURL: "https://reactwebsimon.firebaseio.com",
  projectId: "reactwebsimon",
  storageBucket: "reactwebsimon.appspot.com",
  messagingSenderId: "318517208380",
  appId: "1:318517208380:web:83f49972cdcb36ab93aa39",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

var storage = firebase.storage();
var storageRef= storage.refFromURL("gs://reactwebsimon.appspot.com");

var img = storageRef.child("sg.jpg");

function Test() {
  return (
    <div>
      <Link to="/" style={{ textDecoration: "none" }}>
        <h1 className="Page-header">
          <iron-icon icon="vaadin:home-o"></iron-icon>
          <br />
          Test
        </h1>
      </Link>
      <body>
        {img.toString()}
        <img src={img} alt="not "></img>
      
      </body>
    </div>
  );
}

export default Test;

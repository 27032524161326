import React from "react";
import { Link } from "react-router-dom";
import "../App.css";

function Cv() {
  return (
    <div>
      <Link to="/" style={{ textDecoration: "none" }}>
        <h1 className="Page-header">
          <iron-icon icon="vaadin:home-o"></iron-icon>
          <br />
          Curriculum Vitæ
        </h1>
      </Link>
      <body>not yet implemented</body>
    </div>
  );
}

export default Cv;

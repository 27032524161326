import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Photos from "./Pages/Photos";
import Cv from "./Pages/Cv";
import About from "./Pages/About";
import Test from "./Pages/Test";

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/photos" component={Photos} />
          <Route path="/cv" component={Cv} />
          <Route path="/about" component={About} />
          <Route path="/test" component={Test} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;

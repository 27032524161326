import React from "react";
import { Link } from "react-router-dom";
import "../App.css";

function About() {
  return (
    <div>
      <Link to="/" style={{ textDecoration: "none" }}>
        <h1 className="Page-header">
          <iron-icon icon="vaadin:home-o"></iron-icon>
          <br />
          About
        </h1>
      </Link>
      <body>
        not yet implemented
        <br />
        <br />
        <iframe
          className="App-spotify"
          title="Spotify"
          src="https://open.spotify.com/embed/playlist/7cQOuPNlAZEWeTO6OrAP5M"
          width="280"
          height="400"
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      </body>
    </div>
  );
}

export default About;

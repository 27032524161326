export const testPhotos = [
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/reactwebsimon.appspot.com/o/sg.jpg?alt=media&token=e776d007-98e3-4d33-b07f-99bfd73320d3',
        width: 3,
        height: 4
    },
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/reactwebsimon.appspot.com/o/Gallia1.jpg?alt=media&token=921fc11d-8423-43ef-96b5-3bc8432368ff',
        width: 4.5,
        height: 3
    },
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/reactwebsimon.appspot.com/o/Stras5.jpg?alt=media&token=bec2159f-e605-4c5f-a8d3-e42211dc618c',
        width: 4.5,
        height: 3
    },
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/reactwebsimon.appspot.com/o/DSC00353.jpg?alt=media&token=a43174a6-a644-4345-b165-769de11ede98',
        width: 4.5,
        height: 3
    }
];
import React, { useState, useCallback } from "react";
import "../App.css";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { testPhotos } from "../Content/testPhotos";
import { Link } from "react-router-dom";
import "@vaadin/vaadin-icons";

function Photos() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
      <Link to="/" style={{ textDecoration: "none" }}>
        <h1 className="Page-header">
          <iron-icon icon="vaadin:home-o"></iron-icon>
          <br /> Photography
        </h1>
      </Link>
      <body>
        {/* not yet implemented */}
        <Gallery photos={testPhotos} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={testPhotos.map((x) => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </body>
    </div>
  );
}

export default Photos;
